export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A user is already logged in. Please refresh the page to continue."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password is shorter than 10 characters. To protect your account, please change your password to make it longer."])}
        },
        "oidc": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an option to proceed to the login page."])},
          "error": {
            "auth-ok-user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no TForms account associated with your email address. Please ask your TForms administrator to create an account for you to continue."])},
            "email-not-verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email address has not been verified by your login server. Please contact your server administrator."])},
            "email-claim-not-provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TForms could not access the email address associated with your account. This could be because your server administrator has configured something incorrectly, or has not set an email address for your account. It could also be the result of privacy options that you can choose during the login process. If so, please try again and ensure that your email is shared."])},
            "internal-server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong during login. Please contact your server administrator."])}
          }
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect email address and/or password."])}
        }
      }
    }
  })
}
